<template>
  <div>
    <v-col class="text-right">
      <v-btn id="odUtilsPrintReportBtn"
        depressed
        color="primary"
        @click="printReport()"
        v-if="hideOnPrint"
      >Print Report</v-btn>
    </v-col>
    <v-container >
      <h2 align="center">Official Depository Interest Apportionment</h2>
      <div align="center">
        <span class="ml-4">
          {{`From: ${ fromDate }`}}
        </span>
        <span class="ml-4">
          {{` To: ${ toDate }`}}
        </span>
      </div>
      <hr>
      <v-simple-table dense>
      <template v-slot:default>
        <thead>
          <tr>
            <th>
              Date
            </th>
            <th class="text-right">
              Basis
            </th>
            <th class="text-right"></th>
            <th class="text-right"></th>
            <th class="text-right"></th>
            <th class="text-right"></th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="item in dailyTotal"
            :key="item.name"
          >
            <td>{{ item.date }}</td>
            <td class="text-right">{{ amountFormat(item.basis) }}</td>
            <td class="text-right"></td>
            <td class="text-right"></td>
            <td class="text-right"></td>
            <td class="text-right"></td>
          </tr>
          <tr class="font-weight-black">
            <td>Grand Totals</td>
            <td class="text-right" id="tmc-util-apport-grand-total">
              {{ amountFormat(grandTotals) }}
            </td>
            <td class="text-right">Department Basis</td>
            <td class="text-right">Daily Average</td>
            <td class="text-right">Percent</td>
            <td class="text-right">Interest</td>
          </tr>
          <tr
            v-for="item in deptDetails"
            :key="item.dept"
          >
            <td>{{ `${item.dept}   ${item.desc}` }}</td>
            <td class="text-right"></td>
            <td class="text-right tmc-util-apport-dept-basis">
              {{ amountFormat(item.deptBasis) }}
            </td>
            <td class="text-right">{{ amountFormat(item.dailyAverage) }}</td>
            <td class="text-right">{{ item.percent ? (item.percent).toFixed(4) : '0.00' }}</td>
            <td class="text-right">{{ amountFormat(item.interest) }}</td>
          </tr>
          <tr>
            <td>Remainder to General</td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td class="text-right">
              {{ amountFormat(reminderToGeneral) }}
            </td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
    <hr>
    </v-container>
  </div>
</template>

<script>
// @ is an alias to /src
import { mapGetters, mapState, mapMutations } from 'vuex';
import { toShortDate } from '../../../util/shared/tmc-global';
import {
  amountFormat,
} from '../../../util/shared/vue-global';

export default {
  name: 'OfficialDepositoryInterestApportionmentReport',
  data: () => ({
    hideOnPrint: true,
  }),
  created() {
    this.setDefaultInterestApportionment();
  },
  computed: {
    ...mapGetters([
      'todaysDate',
    ]),
    ...mapState({
      dailyTotal: (state) => {
        if (state.OD.interestApportionment
          && state.OD.interestApportionment.interestApportionmentDetails
          && state.OD.interestApportionment.interestApportionmentDetails.dailyTotal
        ) {
          return state.OD.interestApportionment.interestApportionmentDetails.dailyTotal;
        }
        return [];
      },
      grandTotals: (state) => {
        if (state.OD.interestApportionment
          && state.OD.interestApportionment.interestApportionmentDetails
          && state.OD.interestApportionment.interestApportionmentDetails.grandTotals
        ) {
          return state.OD.interestApportionment.interestApportionmentDetails.grandTotals;
        }
        return [];
      },
      deptDetails: (state) => {
        if (state.OD.interestApportionment
          && state.OD.interestApportionment.interestApportionmentDetails
          && state.OD.interestApportionment.interestApportionmentDetails.deptDetails
        ) {
          const stateDept = state.OD.interestApportionment.interestApportionmentDetails.deptDetails;
          stateDept.sort((a, b) => {
            const deptA = a.dept;
            const deptB = b.dept;
            if (deptA > deptB) {
              return 1;
            }
            return -1;
          });
          return stateDept;
        }
        return [];
      },
      reminderToGeneral: (state) => {
        if (state.OD.interestApportionment
          && state.OD.interestApportionment.interestApportionmentDetails
          && state.OD.interestApportionment.interestApportionmentDetails.reminderToGeneral
        ) {
          return state.OD.interestApportionment.interestApportionmentDetails.reminderToGeneral;
        }
        return [];
      },
      fromDate: (state) => {
        if (state.OD.interestApportionment
          && state.OD.interestApportionment.fromDate
        ) {
          return toShortDate(state.OD.interestApportionment.fromDate);
        }
        return '';
      },
      toDate: (state) => {
        if (state.OD.interestApportionment
          && state.OD.interestApportionment.toDate
        ) {
          return toShortDate(state.OD.interestApportionment.toDate);
        }
        return '';
      },
    }),
  },
  methods: {
    ...mapMutations('OD', [
      'setDefaultInterestApportionment',
    ]),
    amountFormat,
    toShortDate,
    printReport() {
      this.hideOnPrint = false;
      this.$nextTick(() => {
        window.print();
      });
    },
  },
};
</script>
